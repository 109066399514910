import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import axios from "axios";

const Orders = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    const [orders, setOrders] = useState([]);
  useEffect(() => {
    axios.get("get-customer-orders/" + props.customer_id)
      .then(resp => {
        setOrders(resp.data);
      }).catch(err => {
        console.log(err);
      });
  }, [props.customer_id]);

//   console.log(orders);
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="tab-content account dashboard-content pl-50">
            <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
              <div className="card">
                <div className="card">
                  <div className="card-header">
                    <h5 className="mb-0">Your Orders</h5>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Order #</th>
                            <th>Order Number</th>
                            <th>Date</th>
                            <th>Status</th>
                            <th>Total Price</th>
                            <th>Shipping Address</th>
                            {/* <th>Actions</th> */}
                          </tr>
                        </thead>
                        <tbody>
                        {!orders[0] ?
                            (
                              <tr>
                                <td className="text-center" colspan="5">
                                  No orders found!
                                </td>
                              </tr>
                            )
                            :
                            (
                              orders.map((order, key) => {
                                return(
                                <tr key={key}>
                                  <td className="">
                                    {order.id}
                                  </td>
                                  <td className="">
                                    {order.order_number}
                                  </td>
                                  <td className="">
                                    {order.order_date_time}
                                  </td>
                                  <td className="">
                                    {order.status}
                                  </td>
                                  <td className="">
                                    {order.pay_amount}
                                  </td>
                                  <td className="">
                                    {order.shipping_address}
                                  </td>
                                </tr>
                                )
                              }
                              )
                            )
                          }
                          {/* {orders && ()
                          } */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
    );
}

export default Orders;