import React from "react";
import { useCart } from "react-use-cart";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Category from "../Components/Category";
import CartPopup from "../Components/CartPopup.js";

/* import Cart from "./cart"; */

const TopHeader = () => {
  const {
    totalUniqueItems,
  } = useCart();

  let navigate = useNavigate();
  var isLoggedIn = false;
  var user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    isLoggedIn = true;
  }
  const logout = () => {
    if (user) {
      /* user = JSON.parse(localStorage.getItem("user")); */

      var obj = { token_id: user.token_id };
      if (axios.post("customer/logout", obj)) {
        localStorage.removeItem("user");
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'Successfully logged out',
          showConfirmButton: false,
          timer: 1500
        })
        navigate("/"); //To redirect to Homepage
        /*  window.setTimeout(function () {
          window.location.reload();
        }, 200); */
      }
    } else {
      navigate("/");
    }
    isLoggedIn = false;
  };
  return (
    <div className="header-area header-style-1 pt-10 header-height-2">
      
      <div className="header-top header-top-ptb-1 d-none d-lg-block top_underlines">
        {/* <div className="container-fluid"> */}
          {/* ====================>SERACH HEADER-2 */}
          <div className="row">
            <div className="header-bottom header-bottom-bg-color  sticky-bar ">
              {/*   <!-- <div className="container"> --> */}
              <div className="header-wrap header-space-between position-relative">
                <div className="logo logo-width-1 d-block d-lg-none">
                  <Link to="/">
                    <img src="" alt="Ecommerce - By Fatmonk Studio" />
                  </Link>
                </div>
                <div className="header-wrap">
                <div className="header-nav d-none d-lg-flex">
                  <Category/>
                </div>
                <div className="logo logo-width-1">
                  <Link to="/">
                    {/* <img src={Logo} alt="logo" /> */}
                    <p style={{fontSize:'2.5rem'}}>MRC</p>
                  </Link>
                </div>
              </div>
              
                {/* <div className="header-nav d-none d-lg-flex">
                  <Category/>
                </div> */}

                <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                  <nav>
                    <ul>
                      <li>
                        <Link to="/" target="_self" className={window.location.pathname==='/' ? 'd-none' : 'menu'}>
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/products" target="_self" className={window.location.pathname==='/products' ? 'active' : ''}>
                         All Products
                        </Link>
                      </li>
                      <li>
                        <Link to="/faq" target="_self" className={window.location.pathname==='/faq' ? 'active' : ''}>
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link to="/contact" target="_self" className={window.location.pathname==='/contact' ? 'active' : ''}>
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                
                <div className="header-action-right">
                    <div className="header-action-2">
                       <div className="header-action-icon-2">
                       <div className="search-style-2">
                    <form className="form--quick-search">
                      <input
                        type="text"
                        className="input-search-product"
                        name="q"
                        placeholder="Search for items…"
                        /* autoComplete="off" */
                      />
                      <div className="panel--search-result"></div>
                    </form>
                  </div>
                      </div>
                      {/* Cart */}
                      <div className="header-action-icon-2">
                        <Link className="mini-cart-icon" to="/cart">
                          <img alt="Cart" src="/assets/themes/nest/imgs/theme/icons/icon-cart.svg" />
                          <span className="pro-count blue">{totalUniqueItems}</span>
                        </Link>
                        <Link to="/cart">
                          <span className="lable">Cart</span>
                        </Link>
                        {totalUniqueItems === 0 && (
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                            <span>No products in the cart.</span>
                          </div>
                        )}
                        {totalUniqueItems !== 0 && <CartPopup/>}
                      </div>
                      <div className="header-action-icon-2">
                        {!isLoggedIn && (
                          <div>
                          <Link to="#">
                            <img className="svgInject rounded-circle" alt="Account" src="/assets/themes/nest/imgs/theme/icons/icon-user.svg" />
                          </Link>
                            <Link to="#">
                              <span className="lable ml-0">Account</span>
                            </Link>
                            <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                              <ul>
                                <li>
                                  <Link to="/login">
                                    <i className="fi fi-rs-user mr-10"></i>Login
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/register">
                                    <i className="fi fi-rs-user-add mr-10"></i>Register
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                        {isLoggedIn && (
                          <div>
                            <Link to="/customer/dashboard">
                              <span className="lable ml-0">{user.customer_name}</span>
                            </Link>

                            {/* ================================================== */}
                            <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                              <ul>
                                <li>
                                  <Link to="/customer/dashboard">
                                    <i className="fi fi-rs-user mr-10"></i>My Account
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/customer/orders">
                                    <i className="fi fi-rs-location-alt mr-10"></i>Order Tracking
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/customer/wishlist">
                                    <i className="fi fi-rs-heart mr-10"></i>My Wishlist
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/customer/profile">
                                    <i className="fi fi-rs-settings-sliders mr-10"></i>Update profile
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/" onClick={logout}>
                                    <i className="fi fi-rs-sign-out mr-10"></i>Sign out
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                {/* <div className="hotline d-none d-lg-flex">
                  <img src={HeadPhoneIcon} alt="hotline" />
                  <p>
                  <a href="tel:+8801722723891">8801722723891</a><span>24/7 Support Center</span>
                  </p>
                </div> */}
                <div className="header-action-icon-2 d-block d-lg-none">
                  <div className="burger-icon burger-icon-white">
                    <span className="burger-icon-top"></span>
                    <span className="burger-icon-mid"></span>
                    <span className="burger-icon-bottom"></span>
                  </div>
                </div>
                <div className="header-action-right d-block d-lg-none">
                  <div className="header-action-2">
                    <div className="header-action-icon-2">
                      <Link to="#/compare">
                        <img alt="Compare" src="/assets/images/topheader/icon-compare.svg" />
                        <span className="pro-count white compare-count">0</span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link to="#/wishlist">
                        <img alt="Wishlist" src="/assets/images/topheader/icon-heart.svg" />
                        <span className="pro-count white wishlist-count"> 0 </span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link className="mini-cart-icon" to="#">
                        <img alt="Cart" src="/assets/images/topheader/icon-cart.svg" />
                        <span className="pro-count white">0</span>
                      </Link>
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                        <span>No products in the cart.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default TopHeader;
