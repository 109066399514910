import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProductBox from '../Components/ProductBox';
import Layout from '../Layouts/Layout';

const Products = () => {

    useEffect(() => {
        document.title = "Ecommerce - Products";
    }, []);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <Layout>
                <div className="page-header breadcrumb-wrap">
                    <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                        <Link to="/" itemProp="item" title="Home">
                            Home
                            <meta itemProp="name" content="Home" />
                            <meta itemProp="position" content="1" />
                        </Link>
                        <span></span> Products
                        <meta itemProp="name" content="Account information" />
                        <meta itemProp="position" content="2" />
                        <span></span>
                        <meta itemProp="name" content="Angie’s Boomchickapop Sweet &amp; Salty Kettle Corn" />
                        <meta itemProp="position" content="4" />
                    </div>
                </div>

                <br />
                <ProductBox productType="get-products" />
                <br />
            </Layout>

        </div>
    );
}

export default Products;