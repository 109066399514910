import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Layout from "../Layouts/Layout";
// import { omit } from "lodash";


const Login = () => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);
    let [phone, setPhone] = useState("");
    // let [username, setUserName] = useState("");
    let [password, setPassword] = useState("");

    let navigate = useNavigate();
    const [error, setError] = useState("");
    const loginSubmit = (e) => {
        var obj = { customer_contact: phone, customer_password: password };
        axios
            .post("customer/login", obj)
            .then(function (resp) {
                console.log(resp.data);
                var data = resp.data;

                // var user = { token_id: data.token_id, token: data.token, customer_id: data.customer_id, customer_name: data.customer_name };
                var user = data;
                localStorage.setItem("user", JSON.stringify(user));
                if (!data.token_id) {
                    setError(resp.data);
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: resp.data,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
                // console.log(localStorage.getItem("user"));
                if (data.token_id) {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Successfully logged in',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    navigate("/customer/dashboard");
                    window.location.reload(false);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        e.preventDefault();
    };
    // const [values, setValues] = useState({});
    // //Errors
    // const [errors, setErrors] = useState({});

    // const validate = (name, value) => {
    //     //A function to validate each input values

    //     switch (name) {
    //         case 'phone':
    //             if(
    //                 !new RegExp( /^(?:\+?88)?01[13-9]\d{8}$/ ).test(value)
    //             ){
    //                 setErrors({
    //                     ...errors,
    //                     phone:'Enter a valid phone number'}
    //                 )
    //             }else{

    //                 let newObj = omit(errors, "phone");
    //                 setErrors(newObj);
                    
    //             }
    //         break;
            
    //         case 'password':
    //             if(
    //                 !new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/).test(value)
    //             ){
    //                 setErrors(
    //                     {...errors,
    //                     password:'Password should contains atleast 8 charaters and containing uppercase,lowercase and numbers'
    //                 }
    //                 );
                 
    //             }else{

    //                 let newObj = omit(errors, "password");
    //                 setErrors(newObj);
                    
    //             }
    //         break;
            
    //         default:
    //             break;
    //     }
    // }

    // const handleSubmit = (event) => {
        
    //     event.preventDefault();

    //     let val = event.target.phone.value;
    //     validate('phone',val);
    //     setValues({
    //         ...values,
    //         ['phone']:val,
    //     })

    //     val = event.target.password.value;
    //     validate('password',val);
    //     setValues({
    //         ...values,
    //         ['password']:val,
    //     })
    // }
    // console.log(values);
    // console.log(errors);
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <Layout>
                <main className="main" id="main-section">
                    <div className="page-header breadcrumb-wrap">
                        <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                            <a href="/#" itemProp="item" title="Home">
                                Home
                                <meta itemProp="name" content="Home" />
                                <meta itemProp="position" content="1" />
                            </a>
                            <span></span> Login
                            <meta itemProp="name" content="Login" />
                            <meta itemProp="position" content="2" />
                        </div>
                    </div>
                    <div className="page-content pt-150 pb-150">
                        <div className="row">
                            <div className="col-xl-8 col-lg-10 col-md-12 m-auto">
                                <div className="row">
                                    <div className="col-lg-6 pr-30 d-none d-lg-block">
                                        <img className="border-radius-15" src="https://nest.botble.com/storage/general/login-1.png" alt="" />
                                    </div>
                                    <div className="col-lg-6 col-md-8">
                                        <div className="login_wrap widget-taber-content background-white">
                                            <div className="padding_eight_all bg-white">
                                                <div className="heading_s1">
                                                    <h1 className="mb-5">Login</h1>
                                                    <p className="mb-30">
                                                        Don&#039;t have an account? <Link to="/register">Create one</Link>
                                                    </p>
                                                </div>
                                                <span style={{ color: "red" }}>{error}</span>
                                                <form onSubmit={(e) => {loginSubmit(e);}}>
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            name="phone"
                                                            id="phone"
                                                            placeholder="Phone*"
                                                            required
                                                            value={phone}
                                                            onChange={(e) => setPhone(e.target.value)}
                                                        />
                                                        {/* <input
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            placeholder="Email*"
                                                            required
                                                            value={username}
                                                            onChange={(e) => setUserName(e.target.value)}
                                                        /> */}
                                                        {/* {
                                                        errors.phone && <p className="text-danger"><strong>{errors.phone}</strong></p>
                                                        } */}
                                                    </div>{" "}
                                                    <div className="form-group">
                                                        {/*  <input type="password" required name="password"  placeholder="Your password*" /> */}
                                                        <input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Password"
                                                            required
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                        {/* {
                                                        errors.password && <p className="text-danger"><strong>{errors.password}</strong></p>
                                                        } */}
                                                    </div>
                                                    <div className="login_footer form-group mb-50">
                                                        <div className="chek-form">
                                                            <div className="custome-checkbox">
                                                                <input className="form-check-input" type="checkbox" name="remember" id="remember-checkbox" value="" />

                                                                <label className="form-check-label" htmlFor="remember-checkbox">
                                                                    <span>Remember me</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <a className="text-muted" href="/#">
                                                            Forgot password?
                                                        </a>
                                                    </div>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-heading btn-block hover-up">
                                                            Login
                                                        </button>
                                                        {/*  <div class="php-email-form">
                                <button type="submit">Login</button>
                              </div> */}
                                                    </div>
                                                    <div className="text-left"></div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Layout>
        </div>
    );
};

export default Login;
