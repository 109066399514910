import React from "react";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../Layouts/Layout";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
  useEffect(() => {
    document.title = "Ecommerce - Contact";
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
        <Layout>
        <div className="page-header breadcrumb-wrap">
            <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span> Contact
              <meta itemProp="name" content="Account information" />
              <meta itemProp="position" content="2" />
            </div>
        </div>
          <section className="mt-60 mb-60">
            <p></p>
            <div style={{ height: "400px", width: " 100%", position: "relative", textAlign: "right" }}>
              <div style={{ height: "400px", width: "100%", overflow: "hidden", background: "none !important" }}>
                <iframe
                title="Fatmonk Limited"
                  width="100%"
                  height="500"
                  src="https://maps.google.com/maps?q=fatmonk&t=&z=17&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
                
              </div>
            </div>
            <p></p>
            <p></p>
            <div className="mt-50 pb-50">
              <div className="row">
                <div className="col-12">
                  <h4 className="mb-15 text-muted">Address</h4>
                  Level 9, House 116, Road 11, Block E
Banani, Dhaka 1213, Bangladesh
                  <br /> <abbr title="Phone">Phone:</abbr> (+88) 01722723891
                  <br /> <abbr title="Email">Email: </abbr>hello@fatmonk.studio
                  <br />{" "}
                  <a
                    href="https://g.page/fatmonk?share"
                    className="btn btn-outline btn-sm btn-brand-outline font-weight-bold text-brand bg-white text-hover-white mt-20 border-radius-5 btn-shadow-brand hover-up"
                    target="_blank"
                  >
                    <i className="fa fa-map text-muted mr-15"></i>View map
                  </a>
                </div>{" "}
                {/* <div className="col-md-4 mb-4 mb-md-0">
                  <h4 className="mb-15 text-muted">Address</h4>
                  205 North Michigan Avenue, Suite 810, Chicago, 60601, USA
                  <br /> <abbr title="Phone">Phone:</abbr> (+01) 234 567
                  <br /> <abbr title="Email">Email: </abbr>studio@botble.com
                  <br />{" "}
                  <a
                    href="https://maps.google.com/?q=205+North+Michigan+Avenue%2C+Suite+810%2C+Chicago%2C+60601%2C+USA"
                    className="btn btn-outline btn-sm btn-brand-outline font-weight-bold text-brand bg-white text-hover-white mt-20 border-radius-5 btn-shadow-brand hover-up"
                  >
                    <i className="fa fa-map text-muted mr-15"></i>View map
                  </a>
                </div>{" "}
                <div className="col-md-4 mb-4 mb-md-0">
                  <h4 className="mb-15 text-muted">Our Shop</h4>
                  205 North Michigan Avenue, Suite 810, Chicago, 60601, USA
                  <br /> <abbr title="Phone">Phone:</abbr> (+01) 234 567
                  <br /> <abbr title="Email">Email: </abbr>shop@botble.com
                  <br />{" "}
                  <a
                    href="https://maps.google.com/?q=205+North+Michigan+Avenue%2C+Suite+810%2C+Chicago%2C+60601%2C+USA"
                    className="btn btn-outline btn-sm btn-brand-outline font-weight-bold text-brand bg-white text-hover-white mt-20 border-radius-5 btn-shadow-brand hover-up"
                  >
                    <i className="fa fa-map text-muted mr-15"></i>View map
                  </a>
                </div> */}
              </div>
            </div>{" "}
            <hr /> <p></p>
            <p></p>
            <section className="mt-50 pb-50 ">
              <div className="container">
                <div className="row d-flex justify-content-center">
                  <div className="col-xl-8 col-lg-10 m-auto">
                    <div className="contact-from-area  padding-20-row-col wow tmFadeInUp animated animated" style={{ visibility: "visible" }}>
                      <h3 className="mb-10 text-center">Drop Us a Line</h3>{" "}
                      <p className="text-muted mb-30 text-center font-sm">Contact Us For Any Questions</p>{" "}
                      <form method="POST" action="#" acceptCharset="UTF-8" className="contact-form-style text-center contact-form">
                        <input name="_token" type="hidden" defaultValue="Gb27jEHSzftsh0pc0t4vgHBYvaMWwz9DboW5z21c" />{" "}
                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input name="name" defaultValue="" placeholder="Name" type="text" />
                            </div>
                          </div>{" "}
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input type="email" name="email" defaultValue="" placeholder="Email" />
                            </div>
                          </div>{" "}
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input name="address" defaultValue="" placeholder="Address" type="text" />
                            </div>
                          </div>{" "}
                          <div className="col-lg-6 col-md-6">
                            <div className="input-style mb-20">
                              <input name="phone" defaultValue="" placeholder="Phone" type="tel" />
                            </div>
                          </div>{" "}
                          <div className="col-lg-12 col-md-12">
                            <div className="input-style mb-20">
                              <input name="subject" defaultValue="" placeholder="Subject" type="text" />
                            </div>
                          </div>{" "}
                          <div className="col-lg-12 col-md-12">
                            <div className="textarea-style mb-20">
                              <textarea name="content" placeholder="Message"></textarea>
                            </div>
                          </div>{" "}
                          <div className="col-lg-12 col-md-12 mb-20">
                            <input
                              type="hidden"
                              name="g-recaptcha-response"
                              id="g-recaptcha-response-6320ca5020838"
                              defaultValue="03ANYolqs0i20Ag3o1t7ZnpcKsuWa_vlCkI-thQxHrosJ4NhV95CqFBbguExpHUXXfOWOOLIENkIAyWLJ8Qiunon2UY1-gcdvKW8v6QB6v4IheZg_gSSZIC49RYI8B5C4Pb6kE9FQFpovDiAabHbNXqFg6ZL708jwig8LGMhiVTK_FKWUFZgyBhxgJF39lr5p2J80UkkTkI6Ufp_2A7K1zv5b5pV1wwZaPu23a2ZF4PhPnnPl3WYJXY1frYURZ_pxGFm60rIXIxxVnlsfSRMpYToJUN0kzmPbXUiildnHBQHgGuzg61zVkFzGh60o65wAL_u1tTn4HcJOV5DHlZX25WYrtaNq_pSJT85oSf27GZqPHRYrp4iXfqw5X8WsEWhM7wXt-xB4J8wYxbP2FJ6j7b9n9PN0WhSheNGKLEIMkk4Uyuc7uVyRHj9Hl_stWYazoioipDjcF9CmC5rA1L-yFx04yoVyE3AEvBbR7PFkEgTi1evfo_nWPhkWjQV5Dqeaop8MCDdkkMt0M"
                            />
                          </div>{" "}
                          <div className="col-lg-12 col-md-12">
                            <div className="input-style">
                              <input
                                type="text"
                                id="math-group"
                                name="math-captcha"
                                required="required"
                                defaultValue=""
                                placeholder="Please solve the following math function: 6 * 3 = ?"
                              />
                            </div>
                          </div>{" "}
                          <div className="col-lg-12 col-md-12">
                            <button type="submit" className="submit submit-auto-width mt-30">
                              Send message
                            </button>
                          </div>
                        </div>{" "}
                        <div className="form-group text-left">
                          <div className="contact-message contact-success-message mt-30" style={{ display: "none" }}></div>{" "}
                          <div className="contact-message contact-error-message mt-30" style={{ display: "none" }}></div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>{" "}
            <p></p>
          </section>
        </Layout>
    </div>
  );
};

export default ContactUs;
