import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { useCart } from "react-use-cart";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import '../Assets/css/dailyBestSales.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}

const DailyBestSell = () => {
  
  const [products, setProducts] = useState("");
  useEffect(() => {
    axios
      .get("bestseller")
      .then((response) => {
        setProducts(response.data.bestseller);
        // console.log("axios state"+response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    }, []);
  
    var settings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    
    
    const {
      addItem,
      items,
      updateItemQuantity,
    } = useCart();
    return (
      <div className="row ">
        <Slider {...settings}>
          {products &&
            products.map((product) => {
              return (
                <div className="slider-gap" key={product.id}>
                  <div
                    data-wow-delay="0.1s"
                    className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                    style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                  >
                    
                    <div className="product-img-action-wrap">
                      <div className="product-img product-img-zoom">
                        <Link to={"/product/" + product.id+"/" + product.product_name}>
                          <img
                            src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/product_images/large/" + product.product_image}
                            alt={"image is not found of ID : " + product.id}
                            className="default-img"
                          />
                          <img
                            src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/product_images/large/" + product.product_image}
                            alt={"image is not found of ID : " + product.id}
                            className="hover-img"
                          />
                        </Link>
                      </div>
                      <div className="product-action-1">
                        
                        <Link aria-label="Add To Wishlist" to="" data-url="#" className="action-btn hover-up js-add-to-wishlist-button">
                          <i className="fi-rs-heart"></i>
                        </Link>
                       
                      </div>
                    </div>
                    <div className="product-badges product-badges-position product-badges-mrg">
                        <span className="hot">{product.product_discount}%</span>
                  </div>
                    <div className="product-content-wrap">
                      <h2>
                        <Link to={"/product/" + product.id+"/" + product.product_name}>{product.product_name}</Link>
                      </h2>
                      <div className="product-rate-cover">
                        <div className="product-rate d-inline-block">
                          <div className="product-rating" style={{ width: "80%" }}></div>
                        </div>
                        <span className="font-small ml-5 text-muted"> (3)</span>
                      </div>
                      <div className="product-card-bottom">
                      {product.product_discount > 0 ? (
                      <div className="product-price">
                        <span>৳{Math.ceil(product.product_price-((product.product_price*product.product_discount)/100))}</span>
                        <span className="old-price">৳{product.product_price}</span>
                      </div>
                    ) : (
                      <div className="product-price">
                        <span>৳{product.product_price}</span>
                        <span className="old-price">৳{product.product_discount}</span>
                      </div>
                    )}
                    <div className="row justify-content-center p-0">
                      
                      <center className="p-0">
                          {items.find(item => item.id === product.id) ? (
                        <div className="col-9 col-md-9 p-0" style={{ display: 'inline-block' }}>
  
                            <div className="row justify-content-center p-0">
                              <div className='col-4 p-0'>
                                <button type="button" className="btn" onClick={() => updateItemQuantity(items.find(item => item.id === product.id).id, items.find(item => item.id === product.id).quantity - 1)}>-</button>
                              </div>
                              <div className='col-4 p-0'>
                                <input type="number" id='quantity' name="quantity"
                                  className="qty-input p_details_input" value={items.find(item => item.id === product.id).quantity}></input>
                              </div>
                              <div className='col-4 p-0'>
                                <button type="button" className="btn" onClick={() => updateItemQuantity(items.find(item => item.id === product.id).id, items.find(item => item.id === product.id).quantity + 1)}>+</button>
                              </div>
                              {/* <div className="col-md-3 p-0">
                              </div> */}
                            </div>
                          
                        </div>
                        ) : (
                          <div
                            className="p-0"
                            onClick={() =>
                              addItem({
                                id: product.id,
                                name: product.product_name,
                                price: (product.product_discount > 0 ? (Math.ceil(product.product_price - ((product.product_price * product.product_discount) / 100))) : (product.product_price)),
                                quantity: 1,
                                image: axios.defaults.baseURL.slice(0, -4) + "frontend/images/product_images/small/" + product.product_image,
                              })
                            }
                          >
                            
                          <Link aria-label="Add To Cart" to="#" class="action-btn add-to-cart-button btn hover-up" tabindex="-1"  onClick={() =>
                              Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Product added to cart',
                                showConfirmButton: false,
                                timer: 800
                              })
                            }><i class="fi-rs-shopping-cart mr-5"></i>Add
                          </Link>
                          </div>
                        )}
                        </center>
                      </div>
                  </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    );
  }
export default DailyBestSell;
