import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../Layouts/Layout';
import Cabbage from '../Assets/Images/cabbage.png'
import axios from "axios";
import Dashboard from '../Components/Dashboard';
import Profile from '../Components/Profile';
import Orders from '../Components/Orders';
import AddressBook from '../Components/AddressBook';
import ChangePassword from '../Components/ChangePassword';
import AddAddress from '../Components/AddAddress';

const CustomerDashboard = () => {

  useEffect(() => {
    document.title = "Ecommerce - My Account";
  }, []);
  const [loading, setLoading] = useState(false);
  const { pgType } = useParams();
  // const [pageType, setPageType] = useState('dashboard');
  // console.log(pgType);
  // if(pgType){
  //   setPageType(pgType);
  // }
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  var user = JSON.parse(localStorage.getItem("user"));
  var customer_id = null;
  if (user) {
    customer_id = user.customer_id;
  }
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    axios
      .get("get-edit-customer/" + customer_id)
      .then(function (resp) {
        setUserData(resp.data);
        setApiData(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [customer_id]);
  const [apiData, setApiData] = useState("");
  let navigate = useNavigate();
  /*   var isLoggedIn = false; */

  const logout = () => {
    if (user) {
      /* user = JSON.parse(localStorage.getItem("user")); */

      var obj = { token_id: user.token_id };
      if (axios.post("customer/logout", obj)) {
        localStorage.removeItem("user");

        navigate("/"); //To redirect to Homepage
        /*  window.setTimeout(function () {
          window.location.reload();
        }, 200); */
      }
    } else {
      navigate("/");
    }
    /*   isLoggedIn = false; */
  };

  return (
    <div>
      {loading && (
        <div className="loader-container">
          <div className="spinner"></div>
        </div>
      )}
      <Layout>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb" itemType="http://schema.org/BreadcrumbList">
              <Link to="/" itemProp="item" title="Home">
                Home
                <meta itemProp="name" content="Home" />
                <meta itemProp="position" content="1" />
              </Link>
              <span></span> Account information
              <meta itemProp="name" content="Account information" />
              <meta itemProp="position" content="2" />
            </div>
          </div>
        </div>
        <div className="page-content pt-30 pb-150">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="row">
                <div className="col-md-3">
                  <div /* className="page-content pt-150 pb-150" */>

                    <div className="row">
                      <div className="col-4">
                        <img className='img-fluid' src={Cabbage} alt="User" />
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="profile-usertitle-name pt-2">
                        <strong>{user.customer_name}</strong>{" "}
                        <p>
                          <small>
                            {apiData.customer_email}
                            {/* {console.log("Email: " + .customer_email)} */}
                          </small>
                        </p>
                      </div>
                    </div>

                    <hr />
                    <div className="dashboard-menu">
                      <ul className="nav flex-column">
                        <li className="nav-item">
                          <Link
                            to="/customer/dashboard"
                            className={pgType==='dashboard'  ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-settings-sliders mr-10" aria-hidden="true"></i>
                            Dashboard
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/customer/profile"
                            className={pgType==='profile' ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-edit-alt mr-10" aria-hidden="true"></i>
                            Profile
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/customer/orders" className={pgType==='orders' ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-shopping-bag mr-10" aria-hidden="true"></i>
                            Orders
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link to="/customer/address" className={pgType==='address' || pgType==='add-address' ? 'nav-link active' : 'nav-link'}>
                            <i className="fi-rs-marker mr-10" aria-hidden="true"></i>
                            Address books
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link to=" " className="nav-link" aria-controls="dashboard" aria-selected="false">
                            <i className="fi-rs-heart mr-10" aria-hidden="true"></i>
                            Wishlist
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link
                            to="/customer/change-password"
                            className={pgType==='change-password' ? 'nav-link active' : 'nav-link'}
                            aria-controls="dashboard"
                            aria-selected="false"
                          >
                            <i className="fi-rs-key mr-10" aria-hidden="true"></i>
                            Change password
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/login"
                            className="nav-link"
                            aria-controls="dashboard"
                            aria-selected="false"
                            onClick={logout}
                          >
                            <i className="fi-rs-sign-out mr-10" aria-hidden="true"></i>
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-9">
                  {pgType==='dashboard' && (
                  <Dashboard customer_name={userData.customer_name} />
                  )}
                  {pgType==='profile' && (
                  <Profile userData={userData} />
                  )}
                  {pgType==='orders' && (
                  <Orders customer_id={customer_id} />
                  )}
                  {pgType==='address' && (
                  <AddressBook customer_id={customer_id} customer_name={userData.customer_name} />
                  )}
                  {pgType==='change-password' && (
                  <ChangePassword customer_id={customer_id} />
                  )}
                  {pgType==='add-address' && (
                  <AddAddress customer_id={customer_id} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

    </div>
  );
}

export default CustomerDashboard;