import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import '../Assets/css/dailyBestSales.css';
// import Swal from 'sweetalert2'
import cabbage from '../Assets/Images/cabbage.png'
// import strawberry from '../Assets/Images/strawberry.png'
import seed from '../Assets/Images/seed.png'

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}

const ShopByCategories = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    axios.get("get-categories")
      .then(resp => {
        setCategories(resp.data.categories);
      });
  }, []);


  var settings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2700,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div className="">
      <Slider {...settings}>

        {categories[0] !== undefined &&
          (


            categories[0].categories.map((category, index) => {
              return (
                // (255, 252, 235
                <div key={index} className="slider-gap d-flex align-items-center">
                  <div data-wow-delay="1s" className="card-2 fadeIn animated" style={{ backgroundColor: category.category_bg_color, width: '230px', visibility: 'visible' }}>
                    <figure className="img-hover-scale overflow-hidden">
                      <Link to={"/product-categories/" + category.id + '/' + category.category_name}> {category.category_image ? (

                        <img src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/category_images/" + category.category_image} alt={category.category_name} />
                      ) : (<img src={seed} alt={category.category_name} />)} </Link>
                    </figure>
                    <h6><Link to={"/product-categories/" + category.id + '/' + category.category_name} title={category.category_name}>{category.category_name}</Link></h6>
                    <span>Many items</span>
                  </div>
                </div>
              );
            })
          )
        }

        {categories[1] !== undefined && (categories[1].categories.map((category, index) => {
          return (
            <div key={index} className="slider-gap d-flex align-items-center">
              <div data-wow-delay="1s" className="card-2 fadeIn animated" style={{ backgroundColor: category.category_bg_color, width: '230px', visibility: 'visible' }}>
                <figure className="img-hover-scale overflow-hidden">
                  <Link to={"/product-categories/" + category.id + '/' + category.category_name}> {category.category_image ? (

                    <img src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/category_images/" + category.category_image} alt={category.category_name} />
                  ) : (<img src={seed} alt={category.category_name} />)}</Link>
                </figure>
                <h6><Link to={"/product-categories/" + category.id + '/' + category.category_name} title={category.category_name}>{category.category_name}</Link></h6>
                <span>Many items</span>
              </div>
            </div>
          );
        })
        )
        }

      </Slider>
    </div>
  );
};
export default ShopByCategories;
