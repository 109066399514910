import React, { useEffect, useState } from 'react'
import Layout from '../Layouts/Layout.js'
// import axios from 'axios'
// import { useParams, Link } from 'react-router-dom'
import BannerSlider from '../Components/BannerSlider.js'
import ShopByCategories from '../Components/ShopByCategories.js'
import ProductBox from '../Components/ProductBox.js'
// import BestSellBanner from '../Assets/Images/Banner/BestSellBanner.jpg'
import DailyBestSell from '../Components/DailyBestSell.js'
// import { Link } from 'react-router-dom'
// import FeaturedCategory from '../Components/FeaturedCategory.js'



const Home = () => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        document.title = "Ecommerce - Home";
      }, []);

    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <Layout>
                <BannerSlider />

                {/* Featured Categories */}
                {/* <FeaturedCategory/> */}

                {/* Popular Products */}
                <h3 className="" style={{ padding: "20px" }}>
                    Popular Products
                </h3>
                <ProductBox productType="popular" />

                {/* Deals Of The Day */}
                <div>
                    <section className="section-padding pb-5">
                        <h3 style={{ padding: "15px" }}>Deals Of The Day</h3>
                        <ProductBox productType="dealsday" />
                    </section>
                </div>

                {/* Daily Best Sells */}
                <h3 style={{ padding: "15px" }}>Daily Best Sells</h3>
                {/* <div className="row">
                    <div className="col-md-4  col-lg-3 d-sm-none d-md-none d-md-block d-lg-block">
                        <div className="d-xl-flex wow animate__ animate__fadeIn">
                            <div className="banner-img style-2" style={{ backgroundImage: 'URL('+BestSellBanner+')' }}>
                                <div className="banner-text">
                                    <h2 className="mb-100">Bring goods into your home</h2>
                                    <Link to="/products" className="btn btn-xs">
                                        Shop now
                                        <i className="fi-rs-arrow-small-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-9 col-sm-12"> */}
                        <DailyBestSell/>
                    {/* </div>
                </div> */}

                {/* Shop By Categories */}
                <h3 className="" style={{ padding: "20px" }}>
                    Shop By Categories
                </h3>
                <ShopByCategories />
            </Layout>

        </div>
    )
}
export default Home;