import React, {  } from "react";
import Footer from "./Footer";
import Header from "./Header";
import MobileHeader from "./MobileHeader";

function Layout({children}) {


  return (
    <div>
      <MobileHeader />
      <div className='row justify-content-center'>
        <div className='col-lg-11 col-xl-11'>
          <Header/>
          <br />

          {children}

          <br/>
          <Footer/>
        </div>
      </div>
    </div>
  );
}

export default Layout;
