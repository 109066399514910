import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from "react-use-cart";
import { useParams } from "react-router-dom";
import axios from "axios";
// import ProductBox from '../Components/ProductBox';
import Layout from '../Layouts/Layout';
import Swal from 'sweetalert2'

const CategoryWiseProducts = () => {

    useEffect(() => {
        document.title = "Ecommerce - Category Wise Products";
    }, []);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    const {
        addItem,
        items,
        updateItemQuantity,
      } = useCart();

    const { id } = useParams();
    const { cname } = useParams();
    const [products, setProducts] = useState("");
    //   useEffect(() => {
    //     async function callApi() {
    //       const apiResult = await axios.get("get-category-products/" + id);
    //       setApiData(apiResult.data);
    //     }
    //     callApi();
    //     //  console.log('category paaaaaaaaaaaa'); 
    //     //  console.log(apiData); 
    //   }, [apiData]);
    useEffect(() => {
        axios.get("get-category-products/" + id)
            .then(resp => {
                setProducts(resp.data.products);
            });
    }, [id]);
    return (
        <div className="">
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <Layout>
                <main className="main" id="main-section">
                    <div className="page-header breadcrumb-wrap">

                        <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                            <Link to="/" itemProp="item" title="Home">
                                Home
                                <meta itemProp="name" content="Home" />
                                <meta itemProp="position" content="1" />
                            </Link>
                            <span></span>
                            <Link to="/products" itemProp="item" title="Products">
                                Products
                                <meta itemProp="name" content="Products" />
                                <meta itemProp="position" content="2" />
                            </Link>
                            <span></span> {cname}
                            <meta itemProp="name" content={cname} />
                            <meta itemProp="position" content="3" />
                        </div>

                    </div>
                    <div className="row product-grid-4 mt-4">
                    {products &&
                        products.map((product) => {
                        return (
                            <div className="col-6 col-xxl-2 col-xl-2 col-lg-3 col-md-4 mb-lg-0 mb-md-5 mb-sm-5" key={product.id}>
                            <div
                                data-wow-delay="0.1s"
                                className="product-cart-wrap mb-30 wow animate__ animate__fadeIn animated"
                                style={{ visibility: "visible", animationDelay: "0.1s", animationName: "fadeIn" }}
                            >
                                <div className="product-img-action-wrap">
                                <div className="product-img product-img-zoom">
                                    <Link to={"/product/" + product.id + "/" + product.product_name}>
                                    <img
                                        src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/product_images/small/" + product.product_image}
                                        alt="not found img"
                                        className="default-img"
                                    />
                                    </Link>
                                </div>

                                {/* discount percentage */}
                                <div className="product-badges product-badges-position product-badges-mrg">
                                    <span className="hot">{product.product_discount}%</span>
                                </div>
                                </div>
                                <div className="product-content-wrap">
                                <h2>
                                    <Link to={"/product/" + product.id + "/" + product.product_name}>{product.product_name}</Link>
                                </h2>
                                <div className="product-rate-cover">
                                    {product.product_discount > 0 ? (
                                    <div className="product-price">
                                        <span>৳{Math.ceil(product.product_price - ((product.product_price * product.product_discount) / 100))}</span>
                                        <span className="old-price">৳{product.product_price}</span>
                                    </div>
                                    ) : (
                                    <div className="product-price">
                                        <span>৳{product.product_price}</span>
                                        <span className="old-price">৳{product.product_discount}</span>
                                    </div>
                                    )}
                                </div>

                                </div>
                                

                                <div className="product-card-bottom">
                                    <div className="row justify-content-center p-0">
                                    
                                    <center className="p-0">
                                        {items.find(item => item.id === product.id) ? (
                                    <div className="col-12 col-md-9 p-0" style={{ display: 'inline-block' }}>

                                        <div className="row justify-content-center p-0">
                                            <div className='col-4 p-0'>
                                            <button type="button" className="btn" onClick={() => updateItemQuantity(items.find(item => item.id === product.id).id, items.find(item => item.id === product.id).quantity - 1)}>-</button>
                                            </div>
                                            <div className='col-4 p-0'>
                                            <input type="number" id='quantity' name="quantity"
                                                className="qty-input p_details_input" value={items.find(item => item.id === product.id).quantity}></input>
                                            </div>
                                            <div className='col-4 p-0'>
                                            <button type="button" className="btn" onClick={() => updateItemQuantity(items.find(item => item.id === product.id).id, items.find(item => item.id === product.id).quantity + 1)}>+</button>
                                            </div>
                                            {/* <div className="col-md-3 p-0">
                                            </div> */}
                                        </div>
                                        
                                    </div>
                                    ) : (
                                        <div
                                        className="p-0"
                                        onClick={() =>
                                            addItem({
                                            id: product.id,
                                            name: product.product_name,
                                            price: (product.product_discount > 0 ? (Math.ceil(product.product_price - ((product.product_price * product.product_discount) / 100))) : (product.product_price)),
                                            quantity: 1,
                                            image: axios.defaults.baseURL.slice(0, -4) + "frontend/images/product_images/small/" + product.product_image,
                                            })
                                        }
                                        >
                                        
                                        <Link aria-label="Add To Cart" to="#" class="action-btn add-to-cart-button btn hover-up add_to_cart_font" tabindex="-1"  onClick={() =>
                                            Swal.fire({
                                            position: 'top-end',
                                            icon: 'success',
                                            title: 'Product added to cart',
                                            showConfirmButton: false,
                                            timer: 800
                                            })
                                        }><i class="fi-rs-shopping-cart mr-5"></i>Add To Cart
                                        </Link>
                                        </div>
                                    )}
                                    </center>
                                    </div>
                                </div>
                            </div>
                            </div>
                        );
                        })
                    }
                    </div>
                </main>
            </Layout>
        </div>
    );
}

export default CategoryWiseProducts;