import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import '../Assets/css/menu.css'
import CoffeeTea from '../Assets/Images/Category/icon-2.png'

const Category = () => {
  const [toggle, setToggle] = useState(false);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function callApi() {
      const apiResult = await axios.get("get-categories");
      setCategories(apiResult.data.categories);
      // console.log(apiResult.data.categories[0]);
    }
    callApi();
  }, []);
  const [isShown, setIsShown] = useState("");

  // console.log(categories);

  return (
    <div>
      <div className="main-categories-wrap d-none d-lg-block" onClick={() => setToggle(!toggle)}>
        <NavLink to="" className="categories-button-active NavLink">
          <span className="fi-rs-apps"></span> <span className="et">&nbsp;</span>
          {toggle ? <i className="fi-rs-angle-down"></i> : <i className="fi-rs-angle-up"></i>}
        </NavLink>
      </div>
      <div>
        {toggle && (
          <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading open smooth_transition">
            <div className="d-flex categories-dropdown-inner">
              {categories &&
                <div className="">
                  <div>
                    <ul className="menu_list">
                      {(categories.map((catgs, index) => {
                        return (
                          catgs.categories.map((category, index) => {
                            return (
                              <div key={index} className="main_cat_li" onMouseEnter={() => setIsShown(category.id)}
                              onMouseLeave={() => setIsShown(0)}>
                                <NavLink className="NavLink" to={"/product-categories/" + category.id + '/' + category.category_name} onClick={() => setToggle(!toggle)}>
                                  {category.category_image ? (
                                    <div>
                                      <li><img src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/category_images/" + category.category_image} alt={category.category_name} width="30" height="30" />&nbsp;&nbsp;&nbsp;
                                        {category.category_name}
                                      </li>
                                    </div>
                                  ) : (<li><img src={CoffeeTea} alt={category.category_name} width="30" height="30" />&nbsp;&nbsp;&nbsp;
                                    {category.category_name}</li>)}

                                </NavLink>
                                {category.subcategories && category.subcategories.map((subCate, index) => {

                                  return (
                                    isShown===category.id && (
                                  <NavLink key={index} className="SubList" to={"/product-categories/" + category.id + "/" + category.category_name}>
                                    <li className=""><img src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/category_images/" + subCate.category_image} alt={subCate.category_name} width="30" height="30" />&nbsp;&nbsp;&nbsp;
                                    {subCate.category_name}</li>
                                    </NavLink>)
                                    )
                                })}
                              </div>
                            );
                          })
                        )
                      })
                      )}
                    </ul>
                  </div>
                </div>
              }

            </div>
          </div>
        )}
      </div>

    </div>
  );
};
export default Category;
