import axios from "axios";
import React, { useState,useEffect } from "react";
import Layout from "../Layouts/Layout";

const Faq = () => {
  const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);

  const [toggle, setToggle] = useState();

  const setToggleClick = (key) => {
    
    setToggle(key);
    
};
  useEffect(() => {
    document.title = "Ecommerce - Faq";
  }, []);

  const [sections, setSections] = useState([]);

  useEffect(() => {
    async function callApi() {
      const apiResult = await axios.get("get-faqs");
      setSections(apiResult.data.faqs);
    }
    callApi();
  }, []);
  console.log(sections);
  return (
    <div>
      {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
          <Layout>
          <div className="page-header breadcrumb-wrap">
              <div className="breadcrumb" itemScope itemType="http://schema.org/BreadcrumbList">
                <a href="/" itemProp="item" title="Home">
                  Home
                  <meta itemProp="name" content="Home" />
                  <meta itemProp="position" content="1" />
                </a>
                <span></span> Faq
                <meta itemProp="name" content="Account information" />
                <meta itemProp="position" content="2" />
              </div>
          </div>
          <section className="mt-60 mb-60">
              <div className="faqs-list">
              {sections &&
                            sections.map((section, index) => {
                              return <div key={index}>
                                <h4>{section.name}</h4>{" "}
                <div id="faq-accordion-0" className="accordion">
                {section &&
                            section.faqs.map((faq, index) => {
                              return (
                                <div key={index} className="card">
                    <div id="heading-faq-0-0" className="card-header">
                      <h2 className="mb-0">
                      {toggle===faq.question ? (
                        <button
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-faq-0-0"
                          aria-expanded="true"
                          aria-controls="collapse-faq-0-0"
                          className={"btn btn-link btn-block text-left"}
                          onClick={() => setToggleClick(null)}
                        >
                          {faq.question}
                        </button>
                      ) :
                      (
                        <button
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse-faq-0-0"
                          aria-expanded="true"
                          aria-controls="collapse-faq-0-0"
                          className={"btn btn-link btn-block text-left collapsed"}
                          onClick={() => setToggleClick(faq.question)}
                        >
                          {faq.question}
                        </button>
                      )
                      }
                      </h2>
                    </div>{" "}
                    {toggle===faq.question && (
                      <div id="collapse-faq-0-0" aria-labelledby="heading-faq-0-0" data-parent="#faq-accordion-0" className="collapse  show ">
                        <div className="card-body">
                        {faq.answer}
                        </div>
                      </div>
                    )}
                  </div>
                  );
                })}
                </div>{" "}
                </div>;
                })}
              </div>
          </section>
          </Layout>
    </div>
  );
};

export default Faq;
