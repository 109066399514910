import React, { useEffect, useState } from "react";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import '../Assets/css/banner.css';

/* import Slider from "react-slick";
import SliderSource from "./sliderSource"; */

const BannerSlider = () => {
  const [banners, setBanners] = useState([]);
  useEffect(() => {
    axios.get("homepage-banner")
      .then(resp => {
        setBanners(resp.data.banner);
      });
  }, []);

  return (
    <Carousel>
      {banners &&
        banners.map((banner) => {
          return (
            <Carousel.Item key={banner.id}>
              <img className="d-block top-slider-img banner_image" src={axios.defaults.baseURL.slice(0, -4) + "frontend/images/banner_images/" + banner.banner_image} alt={banner.title} />
              <Carousel.Caption>
                <div className="top-left">
                  <div className="banner_text_div">
                    <h1 className="display-22 mb-40 banner_text">
                      {banner.title}
                    </h1>
                    <p className="mb-65 banner_text banner_sub_text">{banner.sub_title}</p>
                  </div>

                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      {/* <Carousel.Item>
        <img className="d-block top-slider-img banner_image" src={Banner1} alt="First slide" />
        <Carousel.Caption>
          <div className="top-left">
            <div className="banner_text_div">
              <h1 className="display-22 mb-40 banner_text">
                All kind of gadgets
                <br /> Big discount
              </h1>
              <p className="mb-65 banner_text banner_sub_text">Sign up for the daily newsletter</p>
            </div>
            
          </div>
        </Carousel.Caption>
      </Carousel.Item> */}

    </Carousel>
  );

};

export default BannerSlider;
