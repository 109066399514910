import React, {useState, useEffect} from "react";
import { useCart } from "react-use-cart";

import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Logo from "../Assets/Images/Logo/logo.png";
import FBLogo from "../Assets/Images/Icons/facebook.png";
import TwitterLogo from "../Assets/Images/Icons/twitter.png";
import InstagramLogo from "../Assets/Images/Icons/instagram.png";
import YoutubeLogo from "../Assets/Images/Icons/youtube.png";

const MobileHeader = () => {
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const {
    totalUniqueItems,
    items,
    removeItem,
  } = useCart();

  let totalPrice = 0;

    items.forEach(function (item) {
        totalPrice += item.quantity * item.price;
    });

  let navigate = useNavigate();
  var isLoggedIn = false;
  var user = JSON.parse(localStorage.getItem("user"));
  if (user) {
    isLoggedIn = true;
  }
  const logout = () => {
    if (user) {
      /* user = JSON.parse(localStorage.getItem("user")); */

      var obj = { token_id: user.token_id };
      if (axios.post("customer/logout", obj)) {
        localStorage.removeItem("user");
        Swal.fire({
          position: 'center',
          icon: 'info',
          title: 'Successfully logged out',
          showConfirmButton: false,
          timer: 1500
        })
        navigate("/"); //To redirect to Homepage
        /*  window.setTimeout(function () {
          window.location.reload();
        }, 200); */
      }
    } else {
      navigate("/");
    }
    isLoggedIn = false;
  };

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    async function callApi() {
      const apiResult = await axios.get("get-categories");
      setCategories(apiResult.data.categories);
      // console.log(apiResult.data.categories[0]);
    }
    callApi();
  }, []);

  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
    axios.get("footer-management")
      .then(resp => {
        setFooterData(resp.data.footerManagement);
      });
  }, []);

  return (
    <div className="display_m_menu">
      <div className="header-bottom header-bottom-bg-color  sticky-bar ">
        <div className="container">
          <div className="header-wrap header-space-between position-relative">
            
            <div className="logo logo-width-1 d-block d-lg-none">
              <Link to="/">
                <img
                  src={Logo}
                  style={{ width: '85px'}}
                  alt="Ecommerce - By Fatmonk Studio"
                />
              </Link>
            </div>

            <div className="header-nav d-none d-lg-flex"></div>

            <div className="header-action-icon-2 d-block d-lg-none">
              <Link to="#">
                <div className="burger-icon burger-icon-white" onClick={() => setToggle(!toggle)}>
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div>
              </Link>
            </div>
            <div className="header-action-right d-block d-lg-none">
              <div className="header-action-2">
                {/* <div className="header-action-icon-2">
                  <a href="#">
                    <img alt="Compare" src="https://nest.botble.com/themes/nest/imgs/theme/icons/icon-compare.svg" />
                    <span className="pro-count white compare-count">0</span>
                  </a>
                </div>
                <div className="header-action-icon-2">
                  <a href="#">
                    <img alt="Wishlist" src="https://nest.botble.com/themes/nest/imgs/theme/icons/icon-heart.svg" />
                    <span className="pro-count white wishlist-count"> 0 </span>
                  </a>
                </div> */}
                <div className="header-action-icon-2">
                  <Link className="mini-cart-icon" to="/cart">
                    <img alt="Cart" src="https://nest.botble.com/themes/nest/imgs/theme/icons/icon-cart.svg" />
                    <span className="pro-count white">{totalUniqueItems}</span>
                  </Link>
                  <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                  {totalUniqueItems === 0 && (
                    <span>No products in the cart.</span>
                  )}
                  {totalUniqueItems !== 0 && 
                  <div className="">
                  {items.map((item, key) => {
                    // console.log("list total : " + key);
                    return (
                      <div key={key}>
                        <ul key={key}>
                          <li key={key}>
                            <div className="shopping-cart-img">
                              <Link to="#">
                                <img alt="Chobani Complete Vanilla Greek" src={item.image} />
                              </Link>
                            </div>
                            <div className="shopping-cart-title">
                              <h4>
                                <Link to={"/product_details/" + item.id}>{item.name} </Link>
                              </h4>
                              <h3>
                              {" "}
                                <span className="d-inline-block">{item.price*item.quantity} ৳</span> <small></small>
                              </h3>
                              <p className="mb-0"></p>
                            </div>
                            <div className="shopping-cart-delete" onClick={() => removeItem(item.id)}>
                              <Link to="#" data-url="https://nest.botble.com/cart/remove/75e8e3e8e64a61cd81ff260ad89b011b" className="remove-cart-item" onClick={() =>
                                          Swal.fire({
                                            position: 'top-end',
                                            icon: 'warning',
                                            title: 'Product removed form the cart',
                                            showConfirmButton: false,
                                            timer: 800
                                          })
                                        }>
                                <i className="fi-rs-cross-small"></i>
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    );
                  })}
                  <div className="shopping-cart-footer">
                    <div className="shopping-cart-total">
                      <h5>
                        <strong className="d-inline-block">Sub Total:</strong>
                              <span>{totalPrice} ৳</span>
                      </h5>
                      <div className="clearfix"></div>
                      <h5>
                        <strong className="d-inline-block">Tax:</strong> <span>-- ৳</span>
                      </h5>
                      <div className="clearfix"></div>
                      <h4>
                      </h4>
                    </div>
                    <div className="shopping-cart-button">
                      <Link to="/cart">View cart</Link>
                      <Link to="/checkout">Checkout</Link>
                    </div>
                  </div>
                </div>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {toggle && (
        <div className="mobile-header-active mobile-header-wrapper-style sidebar-visible .topnav" id="myLinks">
          <div className="mobile-header-wrapper-inner">
            <div className="mobile-header-top">

              <div className="mobile-header-logo">
                <Link to="/" >
                  <img src={Logo} alt="Ecommerce - By Fatmonk Studio" />
                </Link>
              </div>
    
              <Link to="#" onClick={() => setToggle(!toggle)}>
                <div className="mobile-menu-close close-style-wrap close-style-position-inherit">
                  <button className="close-style search-close">
                    <i className="icon-top"></i>
                    <i className="icon-bottom"></i>
                  </button>
                </div>
              </Link>
            </div>
            <div className="mobile-header-content-area">
              <div className="mobile-search search-style-3 mobile-header-border">
                <form
                  action="https://nest.botble.com/products"
                  className="form--quick-search"
                  data-ajax-url="https://nest.botble.com/ajax/search-products"
                  method="get"
                >
                  <input type="text" name="q" className="input-search-product" placeholder="Search for items…" autoComplete="off" />
                  <button type="submit">
                    <i className="fi-rs-search"></i>
                  </button>
                  <div className="panel--search-result"></div>
                </form>
              </div>
              <div className="mobile-menu-wrap mobile-header-border">
                <nav>
                  <ul className="mobile-menu">
                    <li className=" menu-item-has-children  ">
                      <span className="menu-expand"></span>
                      <Link to="/" target="_self">
                        Home
                      </Link>
                    </li>
                    <li className="menu-item-has-children" onClick={() => setToggle2(!toggle2)}>
                      <span className="menu-expand">{toggle2 ? <i className="fi-rs-angle-up"></i> : <i className="fi-rs-angle-down"></i>}</span>
                      <span className="menu-expand"></span>
                      <Link to="#">Categories</Link>
                      {toggle2 && (
                        <ul className="dropdown" style={{ display: "" }}>
                          {(categories.map((catgs, index) => {
                        return (
                          catgs.categories.map((category, index) => {
                            return (
                              <li key={index}>
                              <Link className="" to={"/product-categories/" + category.id+'/'+category.category_name}>
                             
                              <span>{category.category_name}</span>
                              </Link>
                              </li>
                            );
                          })
                        )
                      })
                      )}

                        </ul>
                      )}
                    </li>
                    <li className=" menu-item-has-children  ">
                      <span className="menu-expand"></span>
                      <Link to="/products" target="_self">
                        Products
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/faq" target="_self">
                        FAQ
                      </Link>
                    </li>
                    <li className=" ">
                      <Link to="/contact" target="_self">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-header-info-wrap">
              {!isLoggedIn && (
                <div className="single-mobile-header-info">
                  <div className="single-mobile-header-info">
                    <Link to="/login">
                      <i className="fi-rs-user"></i> Log In / Sign Up
                    </Link>
                  </div>
                  <div className="single-mobile-header-info">
                    <a href={"tel:+"+footerData && (
                              footerData.hotline_no
                            )}>
                      <i className="fi-rs-headphones"></i> +{footerData && (
                              footerData.hotline_no
                            )}
                    </a>
                  </div>
                </div>
              )}
                {isLoggedIn && (
                          <div>
                            <Link to="/customer/dashboard">
                              <span className="lable ml-0">{user.customer_name}</span>
                            </Link>

                            {/* ================================================== */}
                            <div className="">
                              <ul>
                                <li>
                                  <Link to="/customer/dashboard">
                                    <i className="fi fi-rs-user mr-10"></i>My Account
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/customer/orders">
                                    <i className="fi fi-rs-location-alt mr-10"></i>Order Tracking
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/wishlist">
                                    <i className="fi fi-rs-heart mr-10"></i>My Wishlist
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/customer/edit-account">
                                    <i className="fi fi-rs-settings-sliders mr-10"></i>Update profile
                                  </Link>
                                </li>
                                <li>
                                  <Link to="/" onClick={logout}>
                                    <i className="fi fi-rs-sign-out mr-10"></i>Sign out
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
              </div>
              <div className="mobile-social-icon mb-50">
                <h6 className="mb-15">Follow Us</h6>
                <a href="https://www.facebook.com/" title="Facebook">
                  <img src={FBLogo} alt="Facebook" />
                </a>
                <a href="https://www.twitter.com/" title="Twitter">
                  <img src={TwitterLogo} alt="Twitter" />
                </a>
                <a href="https://www.instagram.com/" title="Instagram">
                  <img src={InstagramLogo} alt="Instagram" />
                </a>
                <a href="https://www.youtube.com/" title="Youtube">
                  <img src={YoutubeLogo} alt="Youtube" />
                </a>
              </div>
              <div className="site-copyright">
              {footerData && (
                              footerData.copyright_description
              )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileHeader;
