import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

const AddressBook = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);
    let navigate = useNavigate();
    const [addresses, setAddresses] = useState([]);
    const [defaultAddress, setDefaultAddress] = useState([]);
  useEffect(() => {
    axios
      .get("get-customer-addresses/" + props.customer_id)
      .then(function (resp) {
        // console.log(resp.data);
        setAddresses(resp.data);
        setDefaultAddress(resp.data.find(address => address.is_default == 1));
      })
      .catch((err) => {
        console.log("error : " + err);
      });
  }, [props.customer_id]);
  // console.log(addresses);
  const handleDefaultAddressChange = (new_default_id) => {
    // console.log("set-customer-default-address/" + defaultAddress.id +"/" + new_default_id);
      axios.get("set-customer-default-address/" + defaultAddress.id +"/" + new_default_id)
        .then(function (resp) {
          // console.log(resp.data);
          if (resp.data.success_message) {
            Swal.fire({
                customClass: {
                    icon: 'mt-4'
                },
                position: 'center',
                icon: 'success',
                title: resp.data.success_message,
                showConfirmButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/customer/address");
                    window.location.reload(false);
                }
            });
            window.location.reload(false);
        }
        })
        .catch((err) => {
          console.log(err);
        });
 
  }

  const handleDelete = (id) => {
    Swal.fire({
      customClass: {
          icon: 'mt-4'
      },
      position: 'center',
      icon: 'question',
      title: 'Are You Sure?',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
      if (result.isConfirmed) {
          
    axios
    .get("delete-customer-addresses/"+id)
    .then(function (resp) {
      var data = resp.data;
      // console.log(data);
      if (data.success_message) {
          navigate("/customer/address");
          window.location.reload(false);
      }
    })
    .catch((err) => {
      console.log(err);
    });
          // navigate("/");
          // window.location.reload(false);
      }
  });
  };
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="tab-content account dashboard-content pl-md-150  pt-md-55">
                  <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="card">
                      <div className="row">
                        {addresses.error_message && (
                          <div>
                            <div className="col-12">
                              <div className="alert alert-dark d-flex align-items-center" role="alert">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                  className="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                                  viewBox="0 0 16 16"
                                  role="img"
                                  aria-label="Info:"
                                >
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>
                                <div>
                                {addresses.error_message} <Link to="/customer/add-address">here</Link>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 m-2">
                              <Link className="add-address" to="/customer/add-address">
                                <i className="fa fa-plus"></i>
                                <span>Add a new address</span>
                              </Link>
                            </div>
                          </div>
                        )}

                        {!addresses.error_message && (
                          <div>
                             {addresses &&
                        addresses.map((address) => {
                             return (
                            <div>
                              <div id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab" class="tab-pane fade active show">
                                <div class="card">
                                  <div class="row">
                                    <div class="col-lg-12 dashboard-address-item  is-address-default ">
                                      <div class="card h-100 mb-3 mb-lg-0 mb-2">
                                        <div class="card-header">
                                          <h5 class="mb-0">
                                            {address.name} &nbsp;
                                            {address.is_default ==='1' ? (
                                            <small class="badge bg-primary" style={{float:'right'}}>Default</small>
                                            ) : (
                                              <button style={{float:'right'}} className="btn" onClick={() => handleDefaultAddressChange(address.id)}>Make This Default</button>
                                            )}
                                          </h5>
                                        </div>{" "}
                                        <div class="card-body p-0">
                                          <address className='row'>
                                          <p className="col-6">Phone: {address.phone}</p>
                                          <p className="col-6">Email: {address.email}</p>
                                          <p className="col-6">Address: {address.address}</p>
                                          <p className="col-6">Area: {address.area}</p>
                                          <p className="col-6">City: {address.city}</p>
                                          <p className="col-6">Zip: {address.zip}</p>
                                          </address>{" "}
                                        </div>{" "}
                                        <div class="card-footer border-top-0">
                                          <div class="row">
                                            <div class="col-auto me-auto">
                                              <Link to={"/customer/address/edit/"+address.id}>Edit</Link>
                                            </div>{" "}
                                            <div class="col-auto">
                                              <Link
                                                to="#"
                                                class="text-danger btn-trigger-delete-address"
                                                onClick={(e) => handleDelete(address.id)}
                                              >
                                                Remove
                                              </Link>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                    <div class="col-12 m-2">
                                      <Link to="/customer/add-address" class="add-address">
                                        <i class="fa fa-plus"></i> <span>Add a new address</span>
                                      </Link>
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                            );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

        </div>
    );
}

export default AddressBook;