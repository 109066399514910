import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Dashboard = (props) => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="spinner"></div>
                </div>
            )}
            <div className="tab-content account dashboard-content pl-50">
                    <div className="tab-pane fade active show" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                      <div className="card">
                        <div className="card">
                          <div className="card-header">
                            <h5 style={{ fontFamily: "var(--font-heading),sans-serif", fontWeight: "700", lineHeight: "1.2" }} className="mb-0">
                              Hello {props.customer_name}!{" "}
                            </h5>
                          </div>
                          <div className="card-body">
                            <p>
                              From your account dashboard. you can easily check &amp; view your{" "}
                              <Link to="/customer/orders">recent orders</Link>, manage your{" "}
                              <Link to="/customer/address">shipping and billing addresses</Link> and{" "}
                              <Link to="/customer/edit-account">edit your password and account details.</Link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

        </div>
    );
}

export default Dashboard;