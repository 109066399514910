import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import '../Assets/css/menu.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../Assets/css/footer.css';
import FooterImage from '../Assets/Images/Footer/footer.png';
import FooterBGImage from '../Assets/Images/Footer/foot-background.png';
import FBLogo from "../Assets/Images/Icons/facebook.png";
import TwitterLogo from "../Assets/Images/Icons/twitter.png";
import InstagramLogo from "../Assets/Images/Icons/instagram.png";
import YoutubeLogo from "../Assets/Images/Icons/youtube.png";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div className={className} style={{ ...style, display: "block", background: "black" }} onClick={onClick} />;
}
const Footer = () => {

  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
    axios.get("footer-management")
      .then(resp => {
        setFooterData(resp.data.footerManagement);
      });
  }, []);

  var settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  // render() {
  return (
    <div>
      <footer className="">
        <div className="pb-5">
          {/* ===========================================================================================================================> */}
          <div className="footer1">
            <img src={FooterBGImage} className="fishes f22 subscribe_bg" alt="Footer BG Image" />
            <img src={FooterImage} className="fish f23 subscribe_top_img" alt="Footer Image" />
            <div className="top-left subscribe_div_section">
              <h2 className="display-22 mb-10 fsub" style={{ color: "#0098b8" }}>
                Stay home &amp; get your daily <br />
                needs from our shop
              </h2>
              <p className="fsub fpd subscribe_subtext">Start Your Daily Shopping with Nest Mart Your email address</p>
              <div className="newsletter f24 subscribe_email_field">
                <form className="newsletter-form " method="post" action="https://nest.botble.com/newsletter/subscribe">
                  <input type="hidden" name="_token" value="8JffnNDwInvryiEHQds50PITA35Tf8iOh6tM2m6h" />
                  <div className="form-subscribe d-flex subscribe_field">
                    <input className="slider_text " type="email" name="email" placeholder="Email address" />
                    <button className="btn subscribeButton" type="submit">
                      Subscribe
                    </button>
                  </div>
                  <div className="col-auto">
                    <input type="hidden" name="g-recaptcha-response" id="g-recaptcha-response-62d4da719eba3" />
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* ---------------------------------------------------------- */}
        </div>
        <div className="pb-30  wow animate__animated animate__fadeInUp" data-wow-delay="0">
          <div className="footer-bottom">
                <div className="row justify-content-center pt-3">
                <div className="col-12 col-md-4">
                <b>Address:</b> {footerData && (
                            footerData.address
                  )} <br/>
                  <b>Email:</b> {footerData && (
                            footerData.email
                  )} <br/>
                  <b>Call Us:</b> +{footerData && (
                            footerData.office_contact
                  )}
                  </div>
                <div className="col-12 col-md-4 p-4">
              <div className="mobile-social-icon">
              <h6>Follow Us</h6>
              <a href="https://www.facebook.com/" title="Facebook">
                <img src={FBLogo} alt="Facebook" />
              </a>
              <a href="https://www.twitter.com/" title="Twitter">
                <img src={TwitterLogo} alt="Twitter" />
              </a>
              <a href="https://www.instagram.com/" title="Instagram">
                <img src={InstagramLogo} alt="Instagram" />
              </a>
              <a href="https://www.youtube.com/" title="Youtube">
                <img src={YoutubeLogo} alt="Youtube" />
              </a>
            </div>
            <div className="pt-2">
            {footerData && (
                    footerData.copyright_description
                  )}
            </div>
                </div>
                <div className="col-12 col-md-4">
                <ul className="footer-list mb-sm-5 mb-md-0">
                  <ul className="footer-list wow fadeIn animated mb-sm-5 mb-md-0">
                    <li>
                      <a href="#/terms-conditions">
                        <span>Terms &amp; Conditions</span>
                      </a>
                      
                    </li>
                    <li>
                      <a href="#/returns-exchanges">
                        <span>Returns &amp; Exchanges</span>
                      </a>
                    </li>
                    <li>
                      <a href="#/shipping-delivery">
                        <span>Shipping &amp; Delivery</span>
                      </a>
                    </li>
                  </ul>
                </ul>
                </div>
                </div>
              </div>
        </div>
      </footer>
    </div>
  );
}
export default Footer;
